import { UserRound } from "lucide-react";
import { useTranslation } from "react-i18next";
import { LangToggle } from "../layouts/elements/langToggle";
import { ModeToggle } from "../layouts/elements/modeToggle";
import DarkLogo from "@/assets/dark-logo.svg?react";
import Logo from "@/assets/logo.svg?react";
import { Button } from "@/components/ui/button";

export function AuthHeader() {
  const { t } = useTranslation("auth");

  return (
    <div className="absolute w-full md:w-4/5 px-8 bg-card bg-opacity-80 dark:border-2 dark:bg-background rounded-md py-3 shadow-md top-6 flex justify-between items-center gap-5">
      <div className="hidden md:flex h-6 justify-self-start">
        <div className="flex dark:hidden">
          <DarkLogo />
        </div>
        <div className="hidden dark:flex">
          <Logo />
        </div>
      </div>
      <Button variant="ghost" className={"text-foreground flex gap-1"}>
        <UserRound width={"15px"} />
        {t("sign_up").toUpperCase()}
      </Button>
      <div>
        <LangToggle buttonProps={"text-foreground"} />{" "}
        <ModeToggle buttonProps={"text-foreground"} />
      </div>
    </div>
  );
}
