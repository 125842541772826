import { FC } from "react";
import { useTranslation } from "react-i18next";
import {
  CartesianGrid,
  ComposedChart,
  ErrorBar,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { useViewportSize } from "../useViewportSize";

type OHLCGraphProps = {
  data: Array<{
    name: string;
    open?: number;
    high?: number;
    low?: number;
    close?: number;
    settlement?: number;
    volume?: number;
    open_interest?: number;
    diff: number[];
  }>;
  unit: string;
};

const CustomTooltip = ({ active, payload, label }) => {
  const { t } = useTranslation("graphs");
  if (active && payload && payload.length) {
    return (
      <div className="text-xs bg-graphTooltip text-white p-4 rounded-md">
        <div className="text-lightGray dark:text-muted-foreground">
          {new Date(label).toLocaleString()}
        </div>
        <div>
          <div className="flex items-center gap-4">
            <div
              style={{
                width: "10px",
                height: "10px",
                background: payload[0].fill,
              }}
            />
            <div className="flex justify-between w-full gap-4">
              <div>{t("ohlc.settlement")}</div>
              <div>{payload[0].payload["settlement"]?.toFixed(2) || "-"}</div>
            </div>
          </div>
          {["close", "high", "low", "open", "volume", "open_interest"].map(
            (key) => (
              <div key={key} className="flex items-center gap-4">
                <div
                  style={
                    ["low", "high"].includes(key)
                      ? {
                          width: "10px",
                          height: "10px",
                          background: "#CC0000",
                        }
                      : {}
                  }
                >
                  {["low", "high"].includes(key) ? "" : "-"}
                </div>
                <div className="flex justify-between w-full gap-4">
                  <div>{t("ohlc." + key)}</div>
                  <div>{payload[0].payload[key]?.toFixed(2) || "-"}</div>
                </div>
              </div>
            )
          )}
        </div>
      </div>
    );
  }

  return null;
};

export const OHLCGraph: FC<OHLCGraphProps> = ({ data, unit }) => {
  const x = Math.floor(data.length / 5);
  const { isMobile } = useViewportSize();

  return (
    <ResponsiveContainer width="100%" height="100%">
      <ComposedChart
        data={data}
        margin={{ top: 30, right: 30, left: 45, bottom: 30 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="name"
          tickFormatter={(x) => new Date(x.toString()).toLocaleDateString()}
          interval={x}
          tickMargin={20}
          angle={isMobile ? 45 : 0}
          className="text-xs"
        />
        <YAxis
          tickCount={7}
          tickMargin={20}
          dataKey="settlement"
          domain={["auto", "auto"]}
          orientation="right"
          tickFormatter={(value) => {
            return value > 2000 ? `${value / 1000}k` : value;
          }}
          className="text-xs"
        />
        <Tooltip
          offset={40}
          cursor={{ stroke: "hsl(244.87, 100%, 61.37%)", strokeWidth: 3 }}
          labelFormatter={(label) => new Date(label).toLocaleString()}
          // @ts-expect-error custom solution for tooltip
          content={<CustomTooltip unit={unit} />}
        />
        <Line
          dataKey="settlement"
          type="monotone"
          stroke="#4A3AFF"
          activeDot={{ r: 6 }}
          fill="#4A3AFF"
          strokeWidth={2.25}
          fillOpacity={1}
        >
          <ErrorBar
            stroke="#CC0000"
            dataKey="diff"
            direction="y"
            width={4}
            strokeWidth={3}
          />
        </Line>
      </ComposedChart>
    </ResponsiveContainer>
  );
};
