import { FC } from "react";
import { useTranslation } from "react-i18next";
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { useViewportSize } from "../useViewportSize";

interface CapacityGraphProps {
  data: Array<{
    name: string;
    total_capacity: number;
    total_remit: number;
    biomass: number;
    hard_coal: number;
    hydro_pumped_storage: number;
    natural_gas: number;
    lignite: number;
  }>;
  unit: string;
}

const CustomTooltip = ({ active, payload, label }) => {
  const { t } = useTranslation("graphs");
  if (active && payload && payload.length) {
    return (
      <div className="text-xs bg-graphTooltip text-white p-4 rounded-md">
        <div className="text-lightGray dark:text-muted-foreground">
          {new Date(label).toLocaleString()}
        </div>
        <div>
          {payload
            .filter((x) => x.dataKey !== "sum")
            .map((data) => (
              <div key={data.name} className="flex items-center gap-4">
                <div
                  style={{
                    width: "10px",
                    height: "10px",
                    background: data.fill,
                  }}
                />
                <div className="flex justify-between w-full gap-4">
                  <div>{t(`production.${data.name}`)}</div>
                  <div>{data.value.toFixed(2)}</div>
                </div>
              </div>
            ))}
        </div>
      </div>
    );
  }

  return null;
};

export const CapacityGraph: FC<CapacityGraphProps> = ({ data, unit }) => {
  const x = Math.floor(data.length / 5);
  const { isMobile } = useViewportSize();

  return (
    <ResponsiveContainer width="100%" height="100%">
      <ComposedChart
        data={data}
        margin={{ top: 30, right: 30, left: 45, bottom: 30 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="name"
          tickFormatter={(x) => new Date(x.toString()).toLocaleDateString()}
          interval={x}
          tickMargin={20}
          angle={isMobile ? 45 : 0}
          className="text-xs"
        />
        <YAxis
          tickCount={7}
          tickMargin={20}
          orientation="right"
          tickFormatter={(value) => {
            return value > 2000 ? `${value / 1000}k` : value;
          }}
          className="text-xs"
        />
        <Tooltip
          offset={40}
          cursor={{ stroke: "hsl(244.87, 100%, 61.37%)", strokeWidth: 3 }}
          labelFormatter={(label) => new Date(label).toLocaleString()}
          // @ts-expect-error custom solution for tooltip
          content={<CustomTooltip unit={unit} />}
        />
        <Line
          type="monotone"
          dataKey="total_capacity"
          stroke="#4A3AFF"
          dot={false}
          activeDot={{ r: 6 }}
          fill="#4A3AFF"
          strokeWidth={3}
          fillOpacity={1}
        />
        <Line
          type="monotone"
          dataKey="total_remit"
          stroke="#4A3AFF"
          dot={false}
          activeDot={{ r: 6 }}
          fill="#4A3AFF"
          strokeDasharray="6 6"
          strokeWidth={3}
          fillOpacity={1}
        />
        {[
          "biomass",
          "hard_coal",
          "lignite",
          "hydro_pumped_storage",
          "natural_gas",
        ].map((name, i) => (
          <Bar
            dataKey={name}
            key={name}
            stackId="a"
            fill={`hsl(${(360 / 5) * i}, 50%, 50%)`}
          />
        ))}
      </ComposedChart>
    </ResponsiveContainer>
  );
};
