import { energyApi, energyTypes } from "@repo/api-client";
import { queryOptions } from "@tanstack/react-query";

export const getSingleProductionQueryOptions = (
  { from, to }: energyTypes.FindSingleProductionParams,
  curve: energyTypes.ProductionCurve
) =>
  queryOptions({
    queryKey: ["energy", "production", curve, from, to],
    queryFn: () => energyApi.findSingleProduction(curve, { from, to }),
    refetchInterval: 30 * 1000,
  });

export const getSingleCapacityQueryOptions = (
  { from, to }: energyTypes.SingleCapacityParams,
  curve: energyTypes.CapacityCurve
) =>
  queryOptions({
    queryKey: ["energy", "capacity", curve, from, to],
    queryFn: () => energyApi.findSingleCapacity(curve, { from, to }),
    refetchInterval: 30 * 1000,
  });

export const getCapacityQueryOptions = ({
  from,
  to,
}: energyTypes.CapacityParams) =>
  queryOptions({
    queryKey: ["energy", "capacity", from, to],
    queryFn: () => energyApi.findCapacity({ from, to }),
    refetchInterval: 30 * 1000,
  });

export const getEnergyProductionQueryOptions = ({
  from,
  to,
}: energyTypes.FindProductionParams) =>
  queryOptions({
    queryKey: ["energy", "production", from, to],
    queryFn: () => energyApi.findProduction({ from, to }),
    refetchInterval: 30 * 1000,
  });

export const getOHLCQueryOptions = ({
  currency,
  from,
  to,
}: energyTypes.OHLCParams) =>
  queryOptions({
    queryKey: ["energy", "ohlc", currency, from, to],
    queryFn: () => energyApi.findOHLC({ currency, from, to }),
    refetchInterval: 30 * 1000,
  });

export const getBalanceQueryOptions = ({
  currency,
  from,
  to,
}: energyTypes.BalanceParams) =>
  queryOptions({
    queryKey: ["energy", "balance", currency, from, to],
    queryFn: () => energyApi.balance({ currency, from, to }),
    refetchInterval: 30 * 1000,
  });

export const getSpotQueryOptions = ({
  currency,
  from,
  to,
}: energyTypes.SpotParams) =>
  queryOptions({
    queryKey: ["energy", "spot", currency, from, to],
    queryFn: () => energyApi.spot({ currency: currency, from, to }),
    refetchInterval: 30 * 1000,
  });

export const getResidualQueryOptions = ({
  from,
  to,
}: energyTypes.ResidualParams) =>
  queryOptions({
    queryKey: ["energy", "residual", from, to],
    queryFn: () => energyApi.residual({ from, to }),
    refetchInterval: 30 * 1000,
  });

export const getConsumptionQueryOptions = ({
  from,
  to,
}: energyTypes.ConsumptionParams) =>
  queryOptions({
    queryKey: ["energy", "consumption", from, to],
    queryFn: () => energyApi.consumption({ from, to }),
    refetchInterval: 30 * 1000,
  });

export const getPrecipitationQueryOptions = ({
  from,
  to,
}: energyTypes.PrecipitationParams) =>
  queryOptions({
    queryKey: ["energy", "precipitation", from, to],
    queryFn: () => energyApi.precipitation({ from, to }),
    refetchInterval: 30 * 1000,
  });

export const getTemperatureQueryOptions = ({
  from,
  to,
}: energyTypes.TemperatureParams) =>
  queryOptions({
    queryKey: ["energy", "temperature", from, to],
    queryFn: () => energyApi.temperature({ from, to }),
    refetchInterval: 30 * 1000,
  });
