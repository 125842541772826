import { useTranslation } from "react-i18next";
import { Outlet } from "@tanstack/react-router";
import { AlignJustify } from "lucide-react";
import { FC, useState } from "react";
import { GraphsButtons } from "./graphsButtons";
import { Button } from "@/components/ui/button";

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";

export const GraphsLayout: FC = () => {
  const { t } = useTranslation("graphs");
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="h-full w-full flex flex-col gap-4">
      <DropdownMenu open={isOpen} onOpenChange={setIsOpen} modal={true}>
        <DropdownMenuTrigger asChild>
          <Button
            variant="dashboard"
            size={"xl"}
            className="px-10 rounded-xl w-full"
          >
            {t("graphSelect")}
            <div className="h-9 w-9 flex ml-5 justify-center items-center bg-turquoise rounded-xl">
              <AlignJustify width={"20px"} height={"20px"} color={"white"} />
            </div>
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent
          onClick={() => setIsOpen(false)}
          className="max-h-[400px] w-[380px] overflow-y-auto p-4"
        >
          <GraphsButtons />
        </DropdownMenuContent>
      </DropdownMenu>
      <div className="flex h-full mb-16">
        <Outlet />
      </div>
    </div>
  );
};
