import { Link } from "@tanstack/react-router";
import { MobileNavigation } from "../navigation/mobileNavigation";
import { useTheme } from "../providers/theme-provider";
import { AppStateActions } from "./appStateActions";
import Logo from "@/assets/logo.svg?react";
import DarkLogo from "@/assets/dark-logo.svg?react";
import { SearchInput } from "@/components/layout/search-input";

export function Header() {
  const { theme } = useTheme();

  return (
    <div className="h-16 sticky bg-background top-0 z-10 flex flex-row md:justify-end items-center py-2 md:px-4">
      <MobileNavigation />
      <Link to="/dashboard/graphs">
        <div className="h-8 w-full md:w-auto md:hidden">
          {theme === "dark" ? <Logo /> : <DarkLogo />}
        </div>
      </Link>
      <div className="hidden md:flex">
        <SearchInput />
        <AppStateActions />
      </div>
    </div>
  );
}
