import { FC } from "react";
import { useCurrency } from "../providers/currency-provider";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";

export const CurrencyToggle: FC = () => {
  const { setCurrency, currency } = useCurrency();

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="ghost" size="icon">
          {currency.toUpperCase()}
          <span className="sr-only">Toggle Currency</span>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        <DropdownMenuItem onClick={() => setCurrency("EUR")}>
          {"EUR"}
        </DropdownMenuItem>
        <DropdownMenuItem onClick={() => setCurrency("PLN")}>
          {"PLN"}
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
