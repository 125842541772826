import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { GraphWrapper } from "../elements/graphWrapper";
import { useCapacityGraph } from "../useGraph";
import { GraphLoading } from "../elements/graphLoading";
import { GraphError } from "../elements/graphError";
import { GraphRangePicker, RangeData } from "../elements/graphRangePicker";
import { CapacityGraph } from "../basicGraphs/capacity.graph";

export const Capacity: FC = () => {
  const { t } = useTranslation("graphs");
  const [range, setRange] = useState<RangeData>({});
  const { data } = useCapacityGraph({
    to: range.to,
    from: range.from,
  });

  if (data.isLoading) return <GraphLoading />;
  if (!data.data || data.error) return <GraphError />;

  const { data: d } = data;

  const g = d.map((x) => ({
    name: x.date.toString(),
    biomass: x.biomass_value,
    hard_coal: x.hard_coal_value,
    hydro_pumped_storage: x.hydro_pumped_storage_value,
    natural_gas: x.natural_gas_value,
    lignite: x.lignite_value,
    total_capacity:
      x.biomass_capacity +
      x.hard_coal_capacity +
      x.hydro_pumped_storage_capacity +
      x.natural_gas_capacity +
      x.lignite_capacity,
    total_remit:
      x.biomass_value +
      x.hard_coal_value +
      x.hydro_pumped_storage_value +
      x.natural_gas_value +
      x.lignite_value,
  }));

  const unit = "MWh/h";

  return (
    <GraphWrapper
      name={t("graphTitle.capacity")}
      unit={unit}
      buttons={<GraphRangePicker range={range} handleRangeChange={setRange} />}
      url={"/dashboard/graphs/capacity"}
    >
      <CapacityGraph unit={unit} data={g} />
    </GraphWrapper>
  );
};
