import { Outlet } from "@tanstack/react-router";

export const PublicLayout = () => {
  return (
    <div className="h-full w-full flex flex-col">
      <div className="flex-1 max-h-full overflow-y-auto">
        <Outlet />
      </div>
    </div>
  );
};
