import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { energyTypes } from "@repo/api-client";
import { GraphWrapper } from "../elements/graphWrapper";
import { useSingleProductionGraph } from "../useGraph";
import { GraphLoading } from "../elements/graphLoading";
import { GraphError } from "../elements/graphError";
import { GraphRangePicker, RangeData } from "../elements/graphRangePicker";
import { ProductionGraph } from "../basicGraphs/production.graph";

export const Production: FC<{
  curve: energyTypes.ProductionCurve;
  color?: { hue: number; saturation: number; lightness: number };
}> = ({ curve, color }) => {
  const { t } = useTranslation("graphs");
  const [range, setRange] = useState<RangeData>({});
  const { data } = useSingleProductionGraph(
    { to: range.to, from: range.from },
    curve
  );

  if (data.isLoading) return <GraphLoading />;
  if (!data.data || data.error) return <GraphError />;

  const { data: d } = data;

  const unit = "MWh/h";
  return (
    <GraphWrapper
      name={t("graphTitle." + curve)}
      unit={unit}
      buttons={<GraphRangePicker range={range} handleRangeChange={setRange} />}
      url={"/dashboard/graphs/" + curve}
    >
      <ProductionGraph
        unit={unit}
        data={d.map((item) => ({
          name: item.date.toString(),
          remit: item.remit,
          capacity: item.capacity,
          actual: item.actual,
        }))}
        mainColor={
          color
            ? `hsl(${color.hue}, ${color.saturation}%, ${color.lightness}%)`
            : undefined
        }
      />
    </GraphWrapper>
  );
};
