import { createFileRoute } from "@tanstack/react-router";
import { useTranslation } from "react-i18next";
import { Outages, outagesQueryOptions } from "@/features/outages";

export const Route = createFileRoute("/_protected/_layout/dashboard/outages")({
  loader: ({ context }) => {
    return context.queryClient.prefetchQuery(outagesQueryOptions);
  },
  component: Outages,
  errorComponent: ErrorFallback,
});

function ErrorFallback() {
  const { t } = useTranslation("errors");
  return <div>{t("failed_loading")}</div>;
}
