import { AreaChart, UserRound, Wrench, Grid2x2X } from "lucide-react";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "@tanstack/react-router";
import { useTheme } from "../providers/theme-provider";
import { NavLink } from "./navLink";
import { HelpBadge } from "./helpBadge";
import { UpdateInfo } from "./updateInfo";
import {
  NavigationMenu,
  NavigationMenuList,
} from "@/components/ui/navigation-menu";
import Logo from "@/assets/logo.svg?react";
import DarkLogo from "@/assets/dark-logo.svg?react";

type NavElement = {
  name: string;
  link?: string;
  isDisabled?: boolean;
  icon?: ReactNode;
};

const navElements: NavElement[] = [
  {
    name: "graphs",
    link: "/dashboard/graphs",
    isDisabled: false,
    icon: <AreaChart height={"15px"} width={"15px"} strokeWidth={3} />,
  },
  {
    name: "outages",
    link: "/dashboard/outages",
    isDisabled: false,
    icon: <Wrench height={"15px"} width={"15px"} strokeWidth={3} />,
  },
  {
    name: "capacity_outages",
    link: "/dashboard/capacity-outages",
    isDisabled: false,
    icon: <Grid2x2X height={"15px"} width={"15px"} strokeWidth={3} />,
  },
];

const profileNavElements: NavElement[] = [
  {
    name: "profile",
    link: "/dashboard/profile",
    isDisabled: true,
    icon: <UserRound height={"15px"} width={"15px"} strokeWidth={3} />,
  },
];

export function Navbar() {
  const { t } = useTranslation("layout-menu");
  const { theme } = useTheme();

  return (
    <div>
      <div className="hidden md:flex h-16 justify-center items-center">
        <Link to="/dashboard/graphs">
          <div className="h-8 w-full md:w-auto">
            {theme === "dark" ? <Logo /> : <DarkLogo />}
          </div>
        </Link>
      </div>
      <NavigationMenu>
        <NavigationMenuList>
          {navElements.map((element, id) => (
            <NavLink
              key={id}
              name={t(element.name)}
              link={element.link}
              isDisabled={element.isDisabled}
              icon={element.icon}
            />
          ))}
          <p className="my-5 text-left font-bold text-xs uppercase">
            {t("account_pages")}
          </p>
          {profileNavElements.map((element, id) => (
            <NavLink
              key={id}
              name={t(element.name)}
              link={element.link}
              isDisabled={element.isDisabled}
              icon={element.icon}
            />
          ))}
          <UpdateInfo />
          <HelpBadge />
        </NavigationMenuList>
      </NavigationMenu>
    </div>
  );
}
