import { useQuery } from "@tanstack/react-query";
import { energyTypes } from "@repo/api-client";
import {
  getBalanceQueryOptions,
  getCapacityQueryOptions,
  getConsumptionQueryOptions,
  getEnergyProductionQueryOptions,
  getOHLCQueryOptions,
  getPrecipitationQueryOptions,
  getResidualQueryOptions,
  getSingleCapacityQueryOptions,
  getSingleProductionQueryOptions,
  getSpotQueryOptions,
  getTemperatureQueryOptions,
} from "./graphQueryOptions";

export const useProductionGraph = (
  params: energyTypes.FindProductionParams
) => {
  const data = useQuery(getEnergyProductionQueryOptions(params));

  return { data };
};

export const useOHLCGraph = (params: energyTypes.OHLCParams) => {
  const data = useQuery(getOHLCQueryOptions(params));

  return { data };
};

export const useBalanceGraph = (params: energyTypes.BalanceParams) => {
  const data = useQuery(getBalanceQueryOptions(params));

  return { data };
};

export const useSpotGraph = (params: energyTypes.SpotParams) => {
  const data = useQuery(getSpotQueryOptions(params));

  return { data };
};

export const useResidualGraph = (params: energyTypes.ResidualParams) => {
  const data = useQuery(getResidualQueryOptions(params));

  return { data };
};

export const useConsumptionGraph = (params: energyTypes.ResidualParams) => {
  const data = useQuery(getConsumptionQueryOptions(params));

  return { data };
};

export const usePrecipitationGraph = (
  params: energyTypes.PrecipitationParams
) => {
  const data = useQuery(getPrecipitationQueryOptions(params));

  return { data };
};

export const useTemperatureGraph = (params: energyTypes.TemperatureParams) => {
  const data = useQuery(getTemperatureQueryOptions(params));

  return { data };
};

export const useSingleProductionGraph = (
  params: energyTypes.FindSingleProductionParams,
  curve: energyTypes.ProductionCurve
) => {
  const data = useQuery(getSingleProductionQueryOptions(params, curve));

  return { data };
};

export const useSingleCapacityGraph = (
  params: energyTypes.SingleCapacityParams,
  curve: energyTypes.CapacityCurve
) => {
  const data = useQuery(getSingleCapacityQueryOptions(params, curve));

  return { data };
};

export const useCapacityGraph = (params: energyTypes.CapacityParams) => {
  const data = useQuery(getCapacityQueryOptions(params));

  return { data };
};
