import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useCapacityOutages } from "./useCapacityOutages";
import { style } from "./style";

export const CapacityOutages: FC = () => {
  const { t } = useTranslation("layout-menu");
  const { data } = useCapacityOutages();

  if (data.isLoading || data.isError) return null;

  const html = `
    <html>
      <head>
        <style> ${style} </style>
        <link rel="preconnect" href="https://fonts.googleapis.com">
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
        <link href="https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap" rel="stylesheet">
      </head>
      <body> ${data.data} </body>
    </html>
  `;

  return (
    <div className="flex flex-col h-full px-4 bg-card dark:bg-background p-5 rounded-2xl dark:border">
      <p className="font-bold text-base mb-4">{t("capacity_outages")}</p>
      <iframe
        srcDoc={html}
        title="capacity-outages"
        className="w-full h-[calc(100vh-150px)]"
      />
    </div>
  );
};
