import {
  createContext,
  ReactElement,
  ReactNode,
  useContext,
  useState,
} from "react";
import ReactCountryFlag from "react-country-flag";

export const COUNTRIES = {
  POLAND: "poland",
} as const;
export const countries = Object.values(COUNTRIES);
export type Country = (typeof countries)[number];

export const countriesList: Record<Country, ReactElement> = {
  poland: <ReactCountryFlag countryCode="PL" svg />,
};

type CountryProviderProps = {
  children: ReactNode;
  defaultCountry?: Country;
};

type CountryProviderState = {
  country: Country;
  setCountry: (Country: Country) => void;
};

const initialState: CountryProviderState = {
  country: "poland",
  setCountry: () => null,
};

const CountryProviderContext =
  createContext<CountryProviderState>(initialState);

export function CountryProvider({
  children,
  defaultCountry = "poland",
}: CountryProviderProps) {
  const storageKey = "country";
  const [country, setCountry] = useState<Country>(
    () => (localStorage.getItem(storageKey) as Country) || defaultCountry
  );

  const value = {
    country,
    setCountry: (country: Country) => {
      localStorage.setItem("country", country);
      setCountry(country);
    },
  };

  return (
    <CountryProviderContext.Provider value={value}>
      {children}
    </CountryProviderContext.Provider>
  );
}

export const useCountry = () => {
  const context = useContext(CountryProviderContext);

  if (context === undefined) {
    throw new Error("useCountry must be used within a CountryProvider");
  }

  return context;
};
