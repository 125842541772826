import { Link, ReactNode } from "@tanstack/react-router";
import { FC, PropsWithChildren } from "react";

interface GraphWrapperProps {
  name: string;
  unit: string;
  buttons?: ReactNode;
  url?: string;
}

export const GraphWrapper: FC<PropsWithChildren<GraphWrapperProps>> = ({
  name,
  unit,
  children,
  buttons,
  url,
}) => {
  return (
    <div className={`flex w-full flex-col h-full shadow-md rounded-lg`}>
      <div className="flex flex-col md:flex-row justify-between">
        <div className="p-4">
          <Link
            to={url}
            disabled={!url}
            className="text-lg font-semibold hover:underline"
          >
            {name}
          </Link>
          <p className="text-sm text-muted-foreground"> {unit} </p>
        </div>
        {buttons}
      </div>
      <div className="flex-grow h-[300px]"> {children} </div>
    </div>
  );
};
