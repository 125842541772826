import { FC } from "react";
import { useTranslation } from "react-i18next";
import {
  countriesList,
  Country,
  useCountry,
} from "../providers/countryProvider";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Button } from "@/components/ui/button";

export const CountryToggle: FC = () => {
  const { setCountry, country } = useCountry();
  const { t } = useTranslation("layout-menu");

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="ghost" className="flex items-center gap-2">
          {countriesList[country]}
          {t(`country.${country}`)}
          <span className="sr-only">{t("country.toggle_title")}</span>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        {Object.keys(countriesList).map((country) => (
          <DropdownMenuItem
            key={country}
            onClick={() => setCountry(country as Country)}
            className="flex items-center gap-2"
          >
            {countriesList[country]}
            {t(`country.${country}`)}
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
