import { Menu } from "lucide-react";
import { FC, useState } from "react";
import { AppStateActions } from "../elements/appStateActions";
import { Navbar } from "./navbar";
import {
  Sheet,
  SheetContent,
  SheetFooter,
  SheetTrigger,
} from "@/components/ui/sheet";
import { Button } from "@/components/ui/button";

export const MobileNavigation: FC = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Sheet open={isOpen} onOpenChange={setIsOpen}>
      <SheetTrigger className={"flex md:hidden justify-self-start"}>
        <Button variant="ghost" size="xs" className="text-greyText">
          <Menu />
        </Button>
      </SheetTrigger>
      <SheetContent
        side={"left"}
        className="w-auto h-full flex flex-col justify-between overflow-y-auto"
      >
        <div onClick={() => setIsOpen(false)}>
          <Navbar />
        </div>
        <SheetFooter className="">
          <AppStateActions />
        </SheetFooter>
      </SheetContent>
    </Sheet>
  );
};
