import { FC, PropsWithChildren } from "react";
import { AuthHeader } from "./auth-header";
import Logo from "@/assets/logo.svg?react";

export const AuthLayout: FC<PropsWithChildren> = ({ children }) => {
  return (
    <div className="w-full h-full flex flex-col-reverse md:flex-row md:justify-center justify-end gap-5 md:gap-0">
      <AuthHeader />
      <div className="flex w-full md:w-6/12 self-center justify-center">
        {children}
      </div>
      <div
        className={`flex md:w-6/12 justify-center items-center h-1/3 md:h-5/6 bg-turquoise md:self-start rounded-bl-3xl bg-[url('/auth-texture.png')] dark:border-2 dark:bg-background bg-auto bg-no-repeat bg-center`}
      >
        <div className="h-[50px] md:h-[80px]">
          <Logo />
        </div>
      </div>
    </div>
  );
};
