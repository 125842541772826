import { createFileRoute, Outlet } from "@tanstack/react-router";
import { AuthLayout } from "@/features/auth";

export const Route = createFileRoute("/_public/_auth")({
  component: () => (
    <AuthLayout>
      <Outlet />
    </AuthLayout>
  ),
});
