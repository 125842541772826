import { Link } from "@tanstack/react-router";
import { ReactNode } from "react";
import { NavigationMenuItem } from "@/components/ui/navigation-menu";

type NavLinkProps = {
  name: string;
  link?: string;
  icon?: ReactNode;
  isDisabled?: boolean;
};

export function NavLink({ name, link, icon, isDisabled }: NavLinkProps) {
  return (
    <NavigationMenuItem>
      <Link
        disabled={isDisabled}
        activeProps={{
          className: "active-nav-link",
        }}
        inactiveProps={{
          style: {
            color: "#A0AEC0",
          },
        }}
        to={link}
        className={`text-sm drop-shadow-sm font-bold flex gap-3 h-14 bg-none rounded-2xl w-52 justify-start items-center p-4`}
      >
        <div className={`flex gap-3 h-14 bg-none justify-start items-center`}>
          <div
            id={"icon-box"}
            className={`w-8 h-8 rounded-xl text-turquoise bg-white dark:bg-background dark:border dark:border-turquoise drop-shadow-sm flex justify-center items-center`}
          >
            {icon}
          </div>
          {name}
        </div>
      </Link>
    </NavigationMenuItem>
  );
}
