import { FC } from "react";
import { useTranslation } from "react-i18next";

export const GraphError: FC = () => {
  const { t } = useTranslation("errors");
  return (
    <div className="flex flex-col space-y-3 h-full w-full">
      <div className="space-y-2">
        <h1 className="text-2xl">{t("failed_loading")}</h1>
      </div>
    </div>
  );
};
