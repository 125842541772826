export const style = `
html {
  font-family: "Open Sans", sans-serif;
}

.buttons {
  display: none;
}

.legend ul {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  gap: 35px;
  justify-content: center;
  color: #718096;
  list-style: square;
  padding: 0;
}

.legend ul li {
  text-align: center;
  line-height: 30px;
  font-size: 13px;
  display: flex;
  align-items: center;
}

.legend ul li::before {
  content: "";
  width: 15px;
  height: 15px;
  border-radius: 15%;
  margin-right: 0.5rem;
}

.legend ul .la::before {
  background-color: #48bb78;
}

.legend ul .lb::before {
  background-color: #cbd5e0;
}

.legend ul .lc::before {
  background-color: #4299e1;
}

.legend ul .ld::before {
  background-color: #e53e3e;
}

.legend ul > .title {
  display: none;
}

.tableTop {
  display: none;
}

.nav {
  display: none;
}

.ubytkiMocy {
  clear: both;
  width: 100%;
  border: 0;
  font-size: 12px;
  border-bottom: 3px solid #4fd1c5;
}

.ubytkiMocy th {
  background: #fff;
}

.ubytkiMocy th,
.ubytkiMocy td {
  border-bottom: 1px solid #d5d6d6;
  border-right: 1px solid #d5d6d6;
}

.ubytkiMocy td:last-child,
.ubytkiMocy th:last-child {
  border-right: 0;
}

.ubytkiMocy tr:last-child > td {
  border-bottom: 0;
}

.ubytkiMocy th {
  color: #718096;
  font-size: 11px;
  font-weight: 700;
  text-align: center;
  border-top: 3px solid #4fd1c5;
  border-bottom: 3px solid #4fd1c5;
  height: 30px;
  text-transform: uppercase;
  position: sticky;
  top: 0;
}

.ubytkiMocy td td {
  border-bottom: 0;
}

.ubytkiMocy .details td {
  border-top: 1px solid #d5d6d6;
}

.ubytkiMocy .last {
  border-right: 3px solid #4fd1c5;
}

.ubytkiMocy th[colspan="14"] {
  position: sticky;
  top: 0;
  border-bottom: none;
}

.ubytkiMocy .tableTop th {
  height: 51px !important;
}

.ubytkiMocy .tableTop .nav {
  position: relative;
  width: 100%;
  height: 51px;
  color: #000;
}

.ubytkiMocy .tableTop .nav .btnTx {
  position: absolute;
  top: 15px;
  height: 23px;
  line-height: 23px;
  text-transform: none;
  word-break: break-word;
}

.ubytkiMocy .tableTop .nav .date label {
  height: 23px;
  line-height: 23px;
  display: block;
  float: left;
  width: 100px;
  text-align: right;
  word-break: break-word;
}

.ubytkiMocy tr td {
  background: #eaebec;
}

.ubytkiMocy tr:nth-child(even) td {
  background: #f5f5f5;
}

.ubytkiMocy tr.details td {
  background: #fff;
}

.ubytkiMocy table {
  width: 100%;
  font-size: 12px;
  word-break: break-word;
}

.ubytkiMocy table td {
  height: 30px;
}

.ubytkiMocy .col1 {
  width: 110px;
}

.ubytkiMocy .col2 {
  width: 93px;
}

.ubytkiMocy .col3 {
  width: 84px;
}

.ubytkiMocy .colBox {
  width: 50px;
}

.ubytkiMocy .col1a {
  width: 110px;
  text-align: center;
}

.ubytkiMocy .col2a {
  width: 93px;
  text-align: center;
}

.ubytkiMocy .col3a {
  width: 84px;
  text-align: center;
}

.ubytkiMocy .powerStation .visible td {
  cursor: pointer;
}

.ubytkiMocy .powerStation .visible:hover td {
  background: #4fd1c5 !important;
}

.ubytkiMocy table .colBox {
  width: 50px;
  background: #fff;
}

.ubytkiMocy table .colBox.cal-general {
  background: #cbd5e0;
  color: #fff;
}

.ubytkiMocy table .colBox.cal-ok {
  background: #418560;
  color: #fff;
}

.ubytkiMocy table .details td.colBox.cal-ok {
  background: #48bb78;
  color: #fff;
}

.ubytkiMocy td.colBox {
  text-align: center;
  cursor: default;
}

.ubytkiMocy table .details td.colBox.cal-planned {
  background: #4299e1;
  color: #fff;
  cursor: pointer;
}

.ubytkiMocy table .details td.colBox.cal-unplanned {
  background: #e53e3e;
  color: #fff;
  cursor: pointer;
}

.ubytkiMocy td.colBox.cal-na {
  background: #ffffff;
  color: gray;
}

.ubytkiMocy .powerStation table tr:hover td {
  background: #4fd1c5cc !important;
}

.ubytkiMocy .summary td {
  background: #fff !important;
  height: 22px;
  text-align: right;
  padding-right: 5px;
  border-top: 3px solid #4fd1c5;
}

.ubytkiMocy .summary.bold {
  font-weight: bold;
}

.ubytkiMocy .colSummary {
  width: 95px;
  text-align: left !important;
}
`;
