import { useTranslation } from "react-i18next";
import { Input } from "../ui/input";

export function SearchInput() {
  const { t } = useTranslation("layout-menu");

  return (
    <div className="relative flex gap-3 items-center w-200 px-3 py-2 border-1 border-gray rounded-2xl focus:outline-none focus:border-primary">
      <Input disabled type="text" placeholder={`${t("search")}...`} />
    </div>
  );
}
