import { FC } from "react";
import {
  ResponsiveContainer,
  AreaChart,
  Area,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  ReferenceLine,
} from "recharts";
import { useViewportSize } from "../useViewportSize";

type TimeseriesGraphProps = {
  unit: string;
  data: {
    name: string;
    value: number;
  }[];
  avg?: number;
  color?: string;
};

// colors for timeseries graph
const colors = {
  primary: "#4FD1C5",
  gradient: ["#4FD1C5CC", "#4FD1C580", "#4FD1C533"],
};

const CustomTooltip = ({ active, payload, label, unit }) => {
  if (active && payload && payload.length) {
    return (
      <div className="text-xs bg-graphTooltip text-white p-4 rounded-md">
        <p className="text-lightGray dark:text-muted-foreground">
          {new Date(label).toLocaleString()}
        </p>
        <div className="flex items-center gap-4">
          <div
            style={{
              width: "10px",
              height: "10px",
              background: payload[0].stroke,
            }}
          />
          {payload[0].payload.value} {unit}
        </div>
      </div>
    );
  }

  return null;
};

export const TimeseriesGraph: FC<TimeseriesGraphProps> = ({
  data,
  unit,
  avg,
  color,
}) => {
  const x = Math.floor(data.length / 5);
  const { isMobile } = useViewportSize();

  return (
    <ResponsiveContainer width="100%" height="100%">
      <AreaChart
        data={data}
        margin={{ top: 30, right: 30, left: 45, bottom: 30 }}
      >
        <defs>
          {color ? (
            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="0%" stopColor={color} stopOpacity={0.33} />
              <stop offset="50%" stopColor={color} stopOpacity={0.33} />
              <stop offset="100%" stopColor={color} stopOpacity={0} />
            </linearGradient>
          ) : (
            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
              <stop
                offset="0%"
                stopColor={colors.gradient[0]}
                stopOpacity={0.33}
              />
              <stop
                offset="50%"
                stopColor={colors.gradient[1]}
                stopOpacity={0.33}
              />
              <stop
                offset="100%"
                stopColor={colors.gradient[2]}
                stopOpacity={0}
              />
            </linearGradient>
          )}
        </defs>
        <XAxis
          dataKey="name"
          tickFormatter={(x) => new Date(x.toString()).toLocaleDateString()}
          interval={x}
          tickMargin={20}
          angle={isMobile ? 45 : 0}
          className="text-xs"
        />
        <YAxis
          tickCount={7}
          tickMargin={20}
          orientation="right"
          tickFormatter={(value) => {
            return value > 2000 ? `${value / 1000}k` : value;
          }}
          className="text-xs"
        />
        <CartesianGrid vertical={false} />
        <Tooltip
          offset={40}
          cursor={{ stroke: colors.primary, strokeWidth: 3 }}
          labelFormatter={(label) => new Date(label).toLocaleString()}
          // @ts-expect-error custom solution for tooltip
          content={<CustomTooltip unit={unit} />}
        />
        <Area
          type="monotone"
          dataKey="value"
          activeDot={{ r: 6 }}
          strokeWidth={2.25}
          stroke={color || colors.primary}
          fillOpacity={1}
          fill="url(#colorUv)"
        />
        {avg && <ReferenceLine y={avg} stroke="red" strokeWidth={4} />}
      </AreaChart>
    </ResponsiveContainer>
  );
};
