import { z } from "zod";
import { createFileRoute, redirect } from "@tanstack/react-router";
import { LoginView } from "@/features/auth";

export const Route = createFileRoute("/_public/_auth/login")({
  validateSearch: z.object({
    redirect: z.string().optional().catch(""),
  }),
  beforeLoad: async ({ context, search }) => {
    const auth = await context.auth.getProfile();
    if (auth) {
      throw redirect({
        to: search.redirect || "/dashboard/graphs",
      });
    }
  },
  component: Component,
});

function Component() {
  const search = Route.useSearch();

  return <LoginView redirectUrl={search.redirect || "/dashboard/graphs"} />;
}
