import { FC } from "react";
import {
  ResponsiveContainer,
  ComposedChart,
  Area,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  Line,
} from "recharts";
import { useTranslation } from "react-i18next";
import { useViewportSize } from "../useViewportSize";

const colors = {
  primary: "#4FD1C5",
  secondary: "#D1C54F",
  tertiary: "#4F4FD1",
  gradient: ["#4FD1C5CC", "#4FD1C580", "#4FD1C533"],
  gradientSecondary: ["#D1C54FCC", "#D1C54F80", "#D1C54F33"],
  gradientTertiary: ["#4F4FD1CC", "#4F4FD180", "#4F4FD133"],
};

type ProductionGraphProps = {
  data: Array<{
    name: string;
    remit: number;
    capacity: number;
    actual: number;
  }>;
  unit: string;
  mainColor?: string;
};
const CustomTooltip = ({ active, payload, label, unit }) => {
  const { t } = useTranslation("graphs");
  if (active && payload && payload.length) {
    return (
      <div className="text-xs bg-graphTooltip text-white p-4 rounded-md">
        <div className="text-lightGray dark:text-muted-foreground">
          {new Date(label).toLocaleString()}
        </div>
        <div>
          {payload.map((data) => (
            <div key={data.name} className="flex items-center gap-4">
              <div
                style={{
                  width: "10px",
                  height: "10px",
                  background: data.stroke,
                }}
              />
              {t("production." + data.name)}: {data.value} {unit}
            </div>
          ))}
        </div>
      </div>
    );
  }

  return null;
};

export const ProductionGraph: FC<ProductionGraphProps> = ({
  data,
  unit,
  mainColor,
}) => {
  const x = Math.floor(data.length / 5);
  const { isMobile } = useViewportSize();
  const color = mainColor ? mainColor : colors.primary;

  return (
    <ResponsiveContainer width="100%" height="100%">
      <ComposedChart
        margin={{ top: 30, right: 30, left: 45, bottom: 30 }}
        data={data}
      >
        <defs>
          <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="0%" stopColor={color} stopOpacity={0.33} />
            <stop offset="50%" stopColor={color} stopOpacity={0.33} />
            <stop offset="100%" stopColor={color} stopOpacity={0} />
          </linearGradient>

          <linearGradient id="colorUv2" x1="0" y1="0" x2="0" y2="1">
            <stop
              offset="0%"
              stopColor={colors.gradientSecondary[0]}
              stopOpacity={0.33}
            />
            <stop
              offset="50%"
              stopColor={colors.gradientSecondary[1]}
              stopOpacity={0.33}
            />
            <stop
              offset="100%"
              stopColor={colors.gradientSecondary[2]}
              stopOpacity={0}
            />
          </linearGradient>

          <linearGradient id="colorUv3" x1="0" y1="0" x2="0" y2="1">
            <stop
              offset="0%"
              stopColor={colors.gradientTertiary[0]}
              stopOpacity={0.33}
            />
            <stop
              offset="50%"
              stopColor={colors.gradientTertiary[1]}
              stopOpacity={0.33}
            />
            <stop
              offset="100%"
              stopColor={colors.gradientTertiary[2]}
              stopOpacity={0}
            />
          </linearGradient>
        </defs>
        <XAxis
          dataKey="name"
          tickFormatter={(x) => new Date(x.toString()).toLocaleDateString()}
          interval={x}
          tickMargin={20}
          angle={isMobile ? 45 : 0}
          className="text-xs"
        />
        <YAxis
          tickCount={7}
          tickMargin={20}
          orientation="right"
          tickFormatter={(value) => {
            return value > 2000 ? `${value / 1000}k` : value;
          }}
          className="text-xs"
        />
        <CartesianGrid vertical={false} />
        <Tooltip
          offset={40}
          cursor={{ strokeWidth: 3 }}
          labelFormatter={(label) => new Date(label).toLocaleString()}
          // @ts-expect-error custom solution for tooltip
          content={<CustomTooltip unit={unit} />}
        />
        <Line
          type="monotone"
          dataKey="capacity"
          stroke={colors.secondary}
          dot={false}
          activeDot={{ r: 6 }}
          strokeWidth={2.25}
        />
        <Line
          type="monotone"
          dataKey="remit"
          stroke={colors.tertiary}
          dot={false}
          strokeWidth={2.25}
          activeDot={{ r: 6 }}
        />
        <Area
          type="monotone"
          dataKey="actual"
          activeDot={{ r: 6 }}
          strokeWidth={2.25}
          stroke={color}
          fillOpacity={1}
          fill="url(#colorUv)"
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
};
