import { FC } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useTranslation } from "react-i18next";
import { pl, enUS } from "date-fns/locale";
import { addDays } from "date-fns";
import { Settings2 } from "lucide-react";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../../components/ui/popover";
import { Button } from "../../../components/ui/button";
import { Input } from "../../../components/ui/input";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
} from "../../../components/ui/form";
import { Calendar } from "../../../components/ui/calendar";
import i18n from "@/i18n";
import { ScrollArea } from "@/components/ui/scroll-area";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";

const formSchema = z.object({
  timeFrom: z.string().optional(),
  timeTo: z.string().optional(),
  dateFrom: z.date().optional(),
  dateTo: z.date().optional(),
});

export type RangeData = {
  from?: Date;
  to?: Date;
};

interface GraphRangePickerProps {
  range: RangeData;
  handleRangeChange: (values: RangeData) => void;
}

export const GraphRangePicker: FC<GraphRangePickerProps> = ({
  range,
  handleRangeChange,
}) => {
  const { t } = useTranslation("graphs");

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      timeFrom: range.from
        ? `${(range.from.getHours() < 10 ? "0" : "") + range.from.getHours()}:${(range.from.getMinutes() < 10 ? "0" : "") + range.from.getMinutes()}`
        : "00:00",
      timeTo: range.to
        ? `${(range.to.getHours() < 10 ? "0" : "") + range.to.getHours()}:${(range.to.getMinutes() < 10 ? "0" : "") + range.to.getMinutes()}`
        : "23:59",
      dateFrom: range.from && new Date(range.from),
      dateTo: range.to && new Date(range.to),
    },
  });

  function onSubmit(values: z.infer<typeof formSchema>) {
    const rangeData: RangeData = {};

    if (values.dateFrom) {
      const dateFrom = new Date(values.dateFrom);
      if (values.timeFrom) {
        dateFrom.setHours(parseInt(values.timeFrom.split(":")[0]));
        dateFrom.setMinutes(parseInt(values.timeFrom.split(":")[1]));
      }
      rangeData.from = dateFrom;
    }

    if (values.dateTo) {
      const dateTo = new Date(values.dateTo);
      if (values.timeTo) {
        dateTo.setHours(parseInt(values.timeTo.split(":")[0]));
        dateTo.setMinutes(parseInt(values.timeTo.split(":")[1]));
      } else {
        dateTo.setHours(23);
        dateTo.setMinutes(59);
      }
      rangeData.to = dateTo;
    }

    handleRangeChange(rangeData);
  }

  return (
    <div className={"flex md:justify-center items-start p-4"}>
      <Popover modal={true}>
        <TooltipProvider delayDuration={200}>
          <Tooltip>
            <TooltipTrigger>
              <PopoverTrigger asChild>
                <Button
                  id="date"
                  variant={"ghost"}
                  className={"justify-start text-left font-bold"}
                >
                  <Settings2 width={"20px"} height={"20px"} />
                </Button>
              </PopoverTrigger>
            </TooltipTrigger>
            <TooltipContent>
              <p>{t("range.change_range")}</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
        <PopoverContent
          className="w-auto px-2 py-4 flex flex-col"
          align="start"
        >
          <ScrollArea className="h-96 md:h-auto">
            <Form {...form}>
              <form
                id={"date-range"}
                onSubmit={form.handleSubmit(onSubmit)}
                className="flex flex-col  md:flex-row justify-center gap-2 mb-3"
              >
                <div>
                  <p className={"font-bold text-center"}>{t("range.start")}</p>
                  <FormField
                    control={form.control}
                    name="dateFrom"
                    render={({ field }) => (
                      <FormItem>
                        <FormControl>
                          <Calendar
                            locale={i18n.language === "pl" ? pl : enUS}
                            initialFocus
                            mode="single"
                            defaultMonth={field.value}
                            selected={field.value}
                            onSelect={field.onChange}
                            numberOfMonths={1}
                            disabled={(date) => date > addDays(new Date(), 14)}
                            {...field}
                          />
                        </FormControl>
                      </FormItem>
                    )}
                  />

                  <FormField
                    control={form.control}
                    name="timeFrom"
                    render={({ field }) => (
                      <FormItem>
                        <FormControl>
                          <Input
                            type="time"
                            disabled={!form.getValues("dateFrom")}
                            {...field}
                          />
                        </FormControl>
                      </FormItem>
                    )}
                  />
                </div>
                <div>
                  <p className={"font-bold text-center"}>{t("range.end")}</p>
                  <FormField
                    control={form.control}
                    name="dateTo"
                    render={({ field }) => (
                      <FormItem>
                        <FormControl>
                          <Calendar
                            locale={i18n.language === "pl" ? pl : enUS}
                            mode="single"
                            defaultMonth={field.value}
                            selected={field.value}
                            onSelect={field.onChange}
                            numberOfMonths={1}
                            disabled={(date) =>
                              date < new Date(form.getValues("dateFrom")) ||
                              date > addDays(new Date(), 14)
                            }
                            {...field}
                          />
                        </FormControl>
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="timeTo"
                    render={({ field }) => (
                      <FormItem>
                        <FormControl>
                          <Input
                            type="time"
                            min={form.getValues("timeFrom")}
                            disabled={!form.getValues("dateTo")}
                            {...field}
                          />
                        </FormControl>
                      </FormItem>
                    )}
                  />
                </div>
              </form>
              <Button
                form={"date-range"}
                className="w-full"
                variant="main"
                type="submit"
              >
                {t("range.submit")}
              </Button>
            </Form>
          </ScrollArea>
        </PopoverContent>
      </Popover>
    </div>
  );
};
