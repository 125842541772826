import { useTranslation } from "react-i18next";
import { FC, useState } from "react";
import { useOHLCGraph } from "../useGraph";
import { GraphLoading } from "../elements/graphLoading";
import { GraphError } from "../elements/graphError";
import { GraphWrapper } from "../elements/graphWrapper";
import { GraphRangePicker, RangeData } from "../elements/graphRangePicker";
import { OHLCGraph } from "../basicGraphs/ohlc.graph";
import { useCurrency } from "@/features/layouts/providers/currency-provider";

export const OHLC: FC = () => {
  const { t } = useTranslation("graphs");
  const { currency } = useCurrency();
  const [range, setRange] = useState<RangeData>({});
  const { data } = useOHLCGraph({ currency, from: range.from, to: range.to });

  if (data.isLoading) return <GraphLoading />;
  if (!data.data || data.error) return <GraphError />;

  const d = data.data.data.map((i) => ({
    ...i,
    diff:
      i.low && i.high ? [i.settlement - i.low, i.high - i.settlement] : [0, 0],
  }));

  return (
    <GraphWrapper
      name={t("graphTitle.ohlc")}
      unit={`${currency.toUpperCase()}/MWh`}
      buttons={<GraphRangePicker range={range} handleRangeChange={setRange} />}
      url={"/dashboard/graphs/ohlc"}
    >
      <OHLCGraph unit="MWh/h" data={d} />
    </GraphWrapper>
  );
};
