import { useForm } from "react-hook-form";
import { useNavigate } from "@tanstack/react-router";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useTranslation } from "react-i18next";
import { FC } from "react";
import { useAuth } from "./auth.context";
import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";

const formSchema = z.object({
  login: z.string().min(1, "required"),
  password: z.string().min(1, "required"),
});

type FormType = z.infer<typeof formSchema>;

const defaultValues: FormType = {
  login: "",
  password: "",
};

export const useLogin = (redirectUrl: string) => {
  const auth = useAuth();
  const form = useForm<FormType>({
    resolver: zodResolver(formSchema),
    defaultValues,
  });

  const navigate = useNavigate();

  async function onSubmit(data: FormType) {
    try {
      await auth.login(data.login, data.password);
      navigate({
        to: redirectUrl || "/dashboard/graphs",
      });
    } catch {
      form.setError("login", {
        message: "loginError",
      });
      form.setError("password", {
        message: "loginError",
      });
    }
  }

  return {
    form,
    onSubmit,
  };
};

interface LoginViewProps {
  redirectUrl: string;
}

export const LoginView: FC<LoginViewProps> = ({ redirectUrl }) => {
  const { t } = useTranslation("auth");
  const { form, onSubmit } = useLogin(redirectUrl);
  const errors = form.formState.errors;

  const loginError = errors.login?.message;
  const passwordError = errors.password?.message;

  return (
    <div className="flex flex-col items-center">
      <div className="w-[300px]">
        <h1 className="text-turquoise text-2xl font-bold">
          {t("welcome_back")}
        </h1>
        <p className="text-grey font-bold text-sm my-4">{t("login_message")}</p>
      </div>
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          className="flex flex-col gap-4 rspace-y-8 w-[300px]"
        >
          <FormField
            control={form.control}
            name="login"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t("login")}</FormLabel>
                <FormControl>
                  <Input placeholder={`${t("login")}...`} {...field} />
                </FormControl>
                {loginError && <FormMessage>{t(loginError)}</FormMessage>}
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="password"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t("password")}</FormLabel>
                <FormControl>
                  <Input
                    type="password"
                    placeholder={`${t("password")}...`}
                    {...field}
                  />
                </FormControl>
                {passwordError && <FormMessage>{t(passwordError)}</FormMessage>}
              </FormItem>
            )}
          />
          <Button variant={"main"} type="submit">
            {t("loginButton")}
          </Button>
        </form>
      </Form>
    </div>
  );
};
