import { useTranslation } from "react-i18next";
import { FC, useState } from "react";
import { useProductionGraph } from "../useGraph";
import { BarGraph } from "../basicGraphs/bar.graph";
import { GraphError } from "../elements/graphError";
import { GraphLoading } from "../elements/graphLoading";
import { GraphWrapper } from "../elements/graphWrapper";
import { GraphRangePicker, RangeData } from "../elements/graphRangePicker";

export const TotalProduction: FC<{ distribution?: boolean }> = ({
  distribution,
}) => {
  const { t } = useTranslation("graphs");
  const [range, setRange] = useState<RangeData>({});
  const { data } = useProductionGraph({ to: range.to, from: range.from });
  if (data.isLoading) return <GraphLoading />;
  if (!data.data || data.error) return <GraphError />;

  const g: Array<
    {
      [key: string]: number;
    } & {
      name: string;
    }
  > = [];

  const names = [];
  const map = {};

  for (let i = 0; i < data.data.length; i++) {
    const { name, data: x } = data.data[i];

    names.push(name);

    for (let j = 0; j < x.length; j++) {
      const { d, v } = x[j];
      if (!map[d]) {
        map[d] = {
          name: d,
          sum: v,
          [name]: v,
        };
      } else {
        map[d][name] = v;
        map[d].sum += v;
      }
    }
  }

  for (const key in map) {
    if (distribution) {
      for (const name of names) {
        map[key][name] = (map[key][name] / map[key].sum) * 100;
      }
      map[key].sum = 100;
    }
    g.push(map[key]);
  }

  return (
    <GraphWrapper
      name={t(
        "graphTitle." +
          (distribution ? "production_distribution" : "production")
      )}
      unit={distribution ? "%" : "MWh/h"}
      buttons={<GraphRangePicker range={range} handleRangeChange={setRange} />}
      url={
        "/dashboard/graphs/" +
        (distribution ? "production_distribution" : "production")
      }
    >
      <BarGraph names={names} unit="MWh/h" data={g} />
    </GraphWrapper>
  );
};
