import { FC, useState } from "react";
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useTranslation } from "react-i18next";
import { useSpotGraph } from "../useGraph";
import { GraphWrapper } from "../elements/graphWrapper";
import { GraphRangePicker, RangeData } from "../elements/graphRangePicker";
import { getSpotStats } from "../spotAvgHelper";
import { GraphLoading } from "../elements/graphLoading";
import { GraphError } from "../elements/graphError";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { useCurrency } from "@/features/layouts/providers/currency-provider";
import { percentageAccuracy } from "@/lib/statistics";

type Data = {
  quantified: number | string;
  volue: number | string;
  fixing_i_price: number | string;
  fixing_i_volume: number | string;
  fixing_ii_price: number | string;
  fixing_ii_volume: number | string;
  pse: number | string;
  quantified_accuracy: number | string;
  volue_accuracy: number | string;
};

const SpotTab: FC<{ data: Array<Data> }> = ({ data }) => {
  const { t } = useTranslation("graphs");

  const columns: ColumnDef<Data>[] = [
    {
      header: t("tableData.date"),
      accessorKey: "d",
    },
    {
      header: t("tableData.quantified"),
      accessorKey: "quantified",
    },
    {
      header: t("tableData.volue"),
      accessorKey: "volue",
    },
    {
      header: t("tableData.pse"),
      accessorKey: "pse",
    },
    {
      header: t("tableData.fixing_i_price"),
      accessorKey: "fixing_i_price",
    },
    {
      header: t("tableData.fixing_i_volume"),
      accessorKey: "fixing_i_volume",
    },
    {
      header: t("tableData.fixing_ii_price"),
      accessorKey: "fixing_ii_price",
    },
    {
      header: t("tableData.fixing_ii_volume"),
      accessorKey: "fixing_ii_volume",
    },
    {
      header: t("tableData.quantified_accuracy"),
      accessorKey: "quantified_accuracy",
    },
    {
      header: t("tableData.volue_accuracy"),
      accessorKey: "volue_accuracy",
    },
  ];

  const table = useReactTable({
    data: data,
    columns: columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <Table>
      <TableHeader>
        {table.getHeaderGroups().map((headerGroup) => (
          <TableRow key={headerGroup.id}>
            {headerGroup.headers.map((header) => {
              return (
                <TableHead
                  className="sticky top-0 bg-background"
                  key={header.id}
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </TableHead>
              );
            })}
          </TableRow>
        ))}
      </TableHeader>
      <TableBody>
        {table.getRowModel().rows?.length ? (
          table.getRowModel().rows.map((row) => (
            <TableRow key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <TableCell key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </TableCell>
              ))}
            </TableRow>
          ))
        ) : (
          <TableRow>
            <TableCell colSpan={columns.length} className="h-24 text-center">
              {t("tableData.noResults")}
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};

export const SpotTable = () => {
  const { t } = useTranslation("graphs");
  const { currency } = useCurrency();
  const [range, setRange] = useState<RangeData>({});
  const { data } = useSpotGraph({ currency, to: range.to, from: range.from });

  if (data.isLoading) return <GraphLoading />;
  if (!data.data || data.error) return <GraphError />;

  const { avg } = getSpotStats(data.data);

  const label = t("avg", {
    currency: currency.toUpperCase(),
    avgV1: avg.quantified?.toFixed(2),
    avgV2: avg.volue?.toFixed(2),
    tgeFixI: avg.fixing_i_price?.toFixed(2) || "-",
    tgeFixII: avg.fixing_ii_price?.toFixed(2) || "-",
    pse: avg.pse?.toFixed(2) || "-",
    accV1: avg.quantified_accuracy
      ? avg.quantified_accuracy?.toFixed(2) + "%"
      : "-",
    accV2: avg.volue_accuracy ? avg.volue_accuracy?.toFixed(2) + "%" : "-",
  });

  return (
    <GraphWrapper
      name={t("graphTitle.spot", { unit: currency.toUpperCase() })}
      unit={label}
      url={"/dashboard/graphs/spotTable"}
      buttons={<GraphRangePicker range={range} handleRangeChange={setRange} />}
    >
      <div className="max-h-full px-5 overflow-auto">
        <SpotTab
          data={data.data.data.map((d) => ({
            d: `${new Date(d.d).toLocaleDateString()} ${new Date(d.d).toLocaleTimeString()}`,
            quantified: d.quantified ? Number(d.quantified.toFixed(2)) : "-",
            volue: d.volue ? Number(d.volue.toFixed(2)) : "-",
            fixing_i_price: d.fixing_i_price
              ? Number(d.fixing_i_price?.toFixed(2))
              : "-",
            fixing_i_volume: d.fixing_i_volume
              ? Number(d.fixing_i_volume?.toFixed(2))
              : "-",
            fixing_ii_price: d.fixing_ii_price
              ? Number(d.fixing_ii_price?.toFixed(2))
              : "-",
            fixing_ii_volume: d.fixing_ii_volume
              ? Number(d.fixing_ii_volume?.toFixed(2))
              : "-",
            pse: d.pse ? Number(d.pse?.toFixed(2)) : "-",
            quantified_accuracy:
              d.fixing_i_price && d.quantified
                ? percentageAccuracy(d.fixing_i_price, d.quantified).toFixed(
                    2
                  ) + "%"
                : "-",
            volue_accuracy:
              d.fixing_i_price && d.volue
                ? percentageAccuracy(d.fixing_i_price, d.volue).toFixed(2) + "%"
                : "-",
          }))}
        />
      </div>
    </GraphWrapper>
  );
};
