import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { GraphWrapper } from "../elements/graphWrapper";
import { useSpotGraph } from "../useGraph";
import { GraphLoading } from "../elements/graphLoading";
import { GraphError } from "../elements/graphError";
import { SpotGraph } from "../basicGraphs/spot.graph";
import { GraphRangePicker, RangeData } from "../elements/graphRangePicker";
import { getSpotStats } from "../spotAvgHelper";
import { useCurrency } from "@/features/layouts/providers/currency-provider";

export const Spot: FC = () => {
  const { t } = useTranslation("graphs");
  const { currency } = useCurrency();
  const [range, setRange] = useState<RangeData>({});
  const { data } = useSpotGraph({ currency, to: range.to, from: range.from });

  if (data.isLoading) return <GraphLoading />;
  if (!data.data || data.error) return <GraphError />;

  const {
    data: g,
    avg,
    last_fixing_i_price,
    last_pse,
  } = getSpotStats(data.data);

  const label = t("avg", {
    currency: currency.toUpperCase(),
    avgV1: avg.quantified?.toFixed(2),
    avgV2: avg.volue?.toFixed(2),
    tgeFixI: avg.fixing_i_price?.toFixed(2) || "-",
    tgeFixII: avg.fixing_ii_price?.toFixed(2) || "-",
    pse: avg.pse?.toFixed(2) || "-",
    accV1: avg.quantified_accuracy
      ? avg.quantified_accuracy?.toFixed(2) + "%"
      : "-",
    accV2: avg.volue_accuracy ? avg.volue_accuracy?.toFixed(2) + "%" : "-",
  });

  return (
    <GraphWrapper
      name={t("graphTitle.spot", { unit: currency.toUpperCase() })}
      unit={label}
      buttons={<GraphRangePicker range={range} handleRangeChange={setRange} />}
      url={"/dashboard/graphs/spot"}
    >
      <SpotGraph
        unit={currency.toUpperCase()}
        data={g}
        avg={avg}
        last_fixing_i_price={last_fixing_i_price}
        last_pse={last_pse}
      />
    </GraphWrapper>
  );
};
