import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { GraphWrapper } from "../elements/graphWrapper";
import { useResidualGraph } from "../useGraph";
import { GraphLoading } from "../elements/graphLoading";
import { GraphError } from "../elements/graphError";
import { TimeseriesGraph } from "../basicGraphs/timeseries.graph";
import { GraphRangePicker, RangeData } from "../elements/graphRangePicker";

export const Residual: FC = () => {
  const { t } = useTranslation("graphs");
  const [range, setRange] = useState<RangeData>({});
  const { data } = useResidualGraph({ to: range.to, from: range.from });

  if (data.isLoading) return <GraphLoading />;
  if (!data.data || data.error) return <GraphError />;

  const { data: d, unit } = data.data;

  const g = d.map((x) => ({ name: x.d, value: x.v }));

  return (
    <GraphWrapper
      name={t("graphTitle.residual")}
      unit={unit}
      buttons={<GraphRangePicker range={range} handleRangeChange={setRange} />}
      url={"/dashboard/graphs/residual"}
    >
      <TimeseriesGraph unit={unit} data={g} />
    </GraphWrapper>
  );
};
