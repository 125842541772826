import { FC } from "react";
import { useTranslation } from "react-i18next";
import { CircleAlert, CircleCheck, CircleMinus } from "lucide-react";
import { outagesTypes } from "@repo/api-client";
import { useOutages } from "./useOutages";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";

export const Outages = () => {
  const data = useOutages();
  const { t } = useTranslation("outages");

  return (
    <div className="flex flex-col bg-card dark:bg-background p-5 rounded-2xl dark:border">
      <p className="font-bold px-4 text-base mb-4">{t("outages")}</p>
      <div className="px-4 flex gap-4 text-ring font-bold text-xs">
        <p className="w-1/3 md:w-1/5 uppercase">{t("name")}</p>
        <p className="uppercase w-2/3 md:w-auto">{t("data")}</p>
      </div>
      <Accordion type="multiple">
        {data.data.map((outage) => (
          <Outage key={outage.name} outage={outage} />
        ))}
      </Accordion>
    </div>
  );
};

interface OutageProps {
  outage: outagesTypes.Outage;
}

const Outage: FC<OutageProps> = ({ outage }) => {
  return (
    <AccordionItem
      title={outage.name}
      value={outage.name}
      className="hover:bg-background"
    >
      <AccordionTrigger>
        <div className="w-full px-4 flex flex-row gap-4">
          <div className="w-1/3 md:w-1/5 text-left font-bold text-sm">
            {outage.name}
          </div>
          <div className="flex flex-wrap gap-2 w-2/3 md:w-auto">
            <MiniCapacity
              capacities={[
                ...outage.capacities,
                ...outage.children.map((child) => child.capacities).flat(),
              ]}
            />
          </div>
        </div>
      </AccordionTrigger>
      <AccordionContent className="flex flex-col gap-2">
        {outage.capacities.map((capacity) => (
          <Capacity key={capacity.name} capacity={capacity} />
        ))}

        {outage.children.map((child, i) => {
          return (
            <Children
              key={outage.name + child.name + i}
              name={child.name}
              capacities={child.capacities}
            />
          );
        })}
      </AccordionContent>
    </AccordionItem>
  );
};

interface MiniCapacityProps {
  capacities: Array<outagesTypes.Capacity>;
}

const MiniCapacity: FC<MiniCapacityProps> = ({ capacities }) => {
  return capacities.map((capacity) => {
    const percentage =
      capacity.data.capacity === 0
        ? 0
        : (capacity.data.v / capacity.data.capacity) * 100;
    const c =
      percentage === 100
        ? "hsl(var(--green))"
        : percentage > 50
          ? "hsl(var(--yellow))"
          : "hsl(var(--red))";
    const icon =
      percentage === 100 ? (
        <CircleCheck color={c} />
      ) : percentage > 50 ? (
        <CircleAlert color={c} />
      ) : (
        <CircleMinus color={c} />
      );

    // NOTE: TooltipTrigger causes the following error:
    // Warning: validateDOMNesting(...): <button> cannot appear as a descendant of <button>.
    return (
      <TooltipProvider key={capacity.name}>
        <Tooltip>
          <TooltipTrigger>{icon}</TooltipTrigger>
          <TooltipContent style={{ borderColor: c }}>
            <p>
              {capacity.name} - {percentage.toFixed(2)}%
            </p>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    );
  });
};

interface CapacityProps {
  capacity: outagesTypes.Capacity;
}

const Capacity: FC<CapacityProps> = ({ capacity }) => {
  const { t } = useTranslation("outages");
  const percentage =
    capacity.data.capacity === 0
      ? 0
      : (capacity.data.v / capacity.data.capacity) * 100;

  const c =
    percentage === 100
      ? "hsl(var(--green))"
      : percentage > 50
        ? "hsl(var(--yellow))"
        : "hsl(var(--red))";
  const icon =
    percentage === 100 ? (
      <CircleCheck color={c} />
    ) : percentage > 50 ? (
      <CircleAlert color={c} />
    ) : (
      <CircleMinus color={c} />
    );

  return (
    <div className="px-4 w-full font-bold flex flex-row gap-2 bg-background rounded-md p-3">
      <div>{icon}</div>
      <div>
        <div className="text-textDarkGray">{capacity.name}</div>
        <div className="text-ring">
          <div>
            <span className="font-normal">{t("capacity")}</span>:{" "}
            {capacity.data.v} / {capacity.data.capacity} -{" "}
            {percentage.toFixed(2)}%
          </div>
          <div>
            <span className="font-normal">{t("begin")}</span>:{" "}
            {new Date(capacity.data.begin).toLocaleString()}
          </div>
          <div>
            <span className="font-normal">{t("end")}</span>:{" "}
            {new Date(capacity.data.end).toLocaleString()}
          </div>
        </div>
      </div>
    </div>
  );
};

interface ChildrenProps {
  name: string;
  capacities: Array<outagesTypes.Capacity>;
}

const Children: FC<ChildrenProps> = ({ capacities }) => {
  return capacities.map((capacity) => (
    <Capacity key={capacity.name} capacity={capacity} />
  ));
};
