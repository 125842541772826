import { Balance } from "./dataGraphs/balance";
import { Consumption } from "./dataGraphs/consumption";
import { OHLC } from "././dataGraphs/ohlc";
import { Precipitation } from "./dataGraphs/precipitation";
import { TotalProduction } from "./dataGraphs/totalProduction";
import { Residual } from "./dataGraphs/residual";
import { Spot } from "./dataGraphs/spot";
import { Temperature } from "./dataGraphs/temperature";
import { SpotTable } from "./tables/spotTable";
import { Production } from "./dataGraphs/production";
import { Capacity } from "./dataGraphs/capacity";

export const graphsConfig = {
  spot: <Spot />,
  spotTable: <SpotTable />,
  residual: <Residual />,
  consumption: <Consumption />,
  precipitation: <Precipitation />,
  temperature: <Temperature />,
  balance: <Balance />,
  ohlc: <OHLC />,
  production: <TotalProduction />,
  production_distribution: <TotalProduction distribution />,
  capacity: <Capacity />,
  wind: (
    <Production
      curve="wind"
      color={{ hue: 106, saturation: 65, lightness: 50 }}
    />
  ),
  solar_photovoltaic: (
    <Production
      curve="solar_photovoltaic"
      color={{ hue: 30, saturation: 70, lightness: 50 }}
    />
  ),
  biomass: <Production curve="biomass" />,
  oil: <Production curve="oil" />,
  lignite: <Production curve="lignite" />,
  hard_coal: <Production curve="hard_coal" />,
  derived_gas: <Production curve="derived_gas" />,
  natural_gas: <Production curve="natural_gas" />,
  hydro_reservoir: <Production curve="hydro_reservoir" />,
  hydro_run_of_river: <Production curve="hydro_run_of_river" />,
  hydro_pumped_storage: <Production curve="hydro_pumped_storage" />,
};
