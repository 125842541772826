import { Link } from "@tanstack/react-router";
import { BarChart2, GalleryVerticalEnd } from "lucide-react";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { graphsConfig } from "./graphs.config";
import { Button } from "@/components/ui/button";

export const GraphsButtons: FC = () => {
  const { t } = useTranslation("graphs");

  return (
    <div className="flex flex-col flex-wrap gap-2">
      <Link to={`/dashboard/graphs`}>
        <Button
          variant="dashboard"
          size="xl"
          className="w-full justify-between"
        >
          {t("graphName.all")}
          <div className="h-9 w-9 flex ml-5 justify-center items-center bg-turquoise rounded-xl">
            <GalleryVerticalEnd
              width={"20px"}
              height={"20px"}
              color={"white"}
            />
          </div>
        </Button>
      </Link>
      {Object.keys(graphsConfig).map((graph) => (
        <Link to={`/dashboard/graphs/${graph}`} key={graph}>
          <Button
            variant="dashboard"
            size="xl"
            className="w-full justify-between"
          >
            {t("graphName." + graph)}
            <div className="h-9 w-9 flex ml-5 justify-center items-center bg-turquoise rounded-xl">
              <BarChart2 width={"20px"} height={"20px"} color={"white"} />
            </div>
          </Button>
        </Link>
      ))}
    </div>
  );
};
