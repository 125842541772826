import { useQuery } from "@tanstack/react-query";
import { useCurrency } from "../providers/currency-provider";
import { getUpdatesQueryOptions } from "./layoutQueryOptions";
import { getSpotQueryOptions } from "@/features/graphs";

function dateRangeAccuracy(): { from: Date; to: Date } {
  const to = new Date();
  to.setHours(23, 59, 0, 0);
  const from = new Date();
  from.setDate(to.getDate() - 6);
  from.setHours(0, 0, 0, 0);

  return {
    from,
    to,
  };
}

function dateRangePrediction(): { from: Date; to: Date } {
  const from = new Date();
  from.setDate(from.getDate() + 1);
  from.setHours(0, 0, 0, 0);
  const to = new Date();
  to.setDate(from.getDate() + 6);
  to.setHours(23, 59, 0, 0);

  return {
    from,
    to,
  };
}

export const useUpdate = () => {
  const timeData = useQuery(getUpdatesQueryOptions);
  const { currency } = useCurrency();
  const spotDataAcc = useQuery(
    getSpotQueryOptions({ ...dateRangeAccuracy(), currency })
  );
  const spotDataPred = useQuery(
    getSpotQueryOptions({ ...dateRangePrediction(), currency })
  );

  return { timeData, spotDataPred, spotDataAcc, currency };
};
