import { FC } from "react";
import { Skeleton } from "@/components/ui/skeleton";

export const GraphLoading: FC = () => {
  return (
    <div className="flex flex-col space-y-3 h-full w-full">
      <div className="space-y-2">
        <Skeleton className="h-4 w-[250px]" />
        <Skeleton className="h-4 w-[100px]" />
      </div>
      <Skeleton className="h-full w-full rounded-xl" />
    </div>
  );
};
