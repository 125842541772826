import { SpotPrice } from "@repo/api-client/dist/src/api/energy/types";
import { percentageAccuracy } from "@/lib/statistics";

/**
 * Get the average of the spot prices
 *
 * Fixing price are calculated only on available data.
 * Quantified and volue data is calculated also on data
 * that is only available for fixing to calculate the accurace
 *
 * @param data SpotPrice
 * @returns Object with the statistics
 *
 */
export const getSpotStats = (data: SpotPrice) => {
  const g = data.data.map((x) => ({
    name: x.d,
    quantified: x.quantified && Number(x.quantified.toFixed(2)),
    volue: x.volue && Number(x.volue.toFixed(2)),
    fixing_i_price: x.fixing_i_price && Number(x.fixing_i_price.toFixed(2)),
    fixing_ii_price: x.fixing_ii_price && Number(x.fixing_ii_price.toFixed(2)),
    pse: x.pse && Number(x.pse.toFixed(2)),
  }));

  let quantified_sum = 0,
    quantified_count = 0,
    volue_sum = 0,
    volue_count = 0,
    fixing_i_price_sum = 0,
    fixing_i_price_count = 0,
    fixing_ii_price_sum = 0,
    fixing_ii_price_count = 0,
    pse_sum = 0,
    pse_count = 0;

  let last_fixing_i_price = null;
  let last_pse = null;

  for (let i = 0; i < g.length; i++) {
    if (g[i].quantified !== null) {
      quantified_sum += g[i].quantified;
      quantified_count++;
    }

    if (g[i].volue !== null) {
      volue_sum += g[i].volue;
      volue_count++;
    }

    if (g[i].fixing_i_price !== null) {
      fixing_i_price_sum += g[i].fixing_i_price;
      fixing_i_price_count++;
      last_fixing_i_price = g[i].name;
    }

    if (g[i].fixing_ii_price !== null) {
      fixing_ii_price_sum += g[i].fixing_ii_price;
      fixing_ii_price_count++;
    }

    if (g[i].pse !== null) {
      pse_sum += g[i].pse;
      last_pse = g[i].name;
      pse_count++;
    }
  }

  const avg = {
    quantified:
      quantified_count != 0 ? quantified_sum / quantified_count : null,
    volue: volue_count != 0 ? volue_sum / volue_count : null,
    fixing_i_price:
      fixing_i_price_count != 0
        ? fixing_i_price_sum / fixing_i_price_count
        : null,
    fixing_ii_price:
      fixing_ii_price_count != 0
        ? fixing_ii_price_sum / fixing_ii_price_count
        : null,
    pse: pse_count != 0 ? pse_sum / pse_count : null,
    quantified_accuracy: null,
    volue_accuracy: null,
  };

  if (fixing_i_price_count > 0 && quantified_count > 0) {
    avg.quantified_accuracy = percentageAccuracy(
      avg.fixing_i_price,
      quantified_sum / fixing_i_price_count
    );
  }

  if (fixing_i_price_count > 0 && volue_count > 0) {
    avg.volue_accuracy = percentageAccuracy(
      avg.fixing_i_price,
      volue_sum / fixing_i_price_count
    );
  }

  return {
    data: g,
    avg,
    last_fixing_i_price,
    last_pse,
  };
};
