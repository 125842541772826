import { createFileRoute } from "@tanstack/react-router";
import { graphsConfig } from "@/features/graphs";

export const Route = createFileRoute(
  "/_protected/_layout/dashboard/graphs/$id"
)({
  component: Component,
});

function Component() {
  const params = Route.useParams();

  if (graphsConfig[params.id]) return graphsConfig[params.id];
}
