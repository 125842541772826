import { createContext, ReactNode, useContext, useState } from "react";

export type Currency = "EUR" | "PLN";

type CurrencyProviderProps = {
  children: ReactNode;
  defaultCurrency?: Currency;
};

type CurrencyProviderState = {
  currency: Currency;
  setCurrency: (currency: Currency) => void;
};

const initialState: CurrencyProviderState = {
  currency: "PLN",
  setCurrency: () => null,
};

const CurrencyProviderContext =
  createContext<CurrencyProviderState>(initialState);

export function CurrencyProvider({
  children,
  defaultCurrency = "PLN",
}: CurrencyProviderProps) {
  const storageKey = "currency";
  const [currency, setCurrency] = useState<Currency>(
    () => (localStorage.getItem(storageKey) as Currency) || defaultCurrency
  );

  const value = {
    currency,
    setCurrency: (currency: Currency) => {
      localStorage.setItem("currency", currency);
      setCurrency(currency);
    },
  };

  return (
    <CurrencyProviderContext.Provider value={value}>
      {children}
    </CurrencyProviderContext.Provider>
  );
}

export const useCurrency = () => {
  const context = useContext(CurrencyProviderContext);

  if (context === undefined) {
    throw new Error("useCurrency must be used within a CurrencyProvider");
  }

  return context;
};
