import { useTranslation } from "react-i18next";
import { useNavigate } from "@tanstack/react-router";
import { LangToggle } from "./langToggle";
import { ModeToggle } from "./modeToggle";
import { CurrencyToggle } from "./currencyToggle";
import { CountryToggle } from "./countryToggle";
import { useAuth } from "@/features/auth";
import { Button } from "@/components/ui/button";

export function AppStateActions() {
  const { t } = useTranslation("layout-menu");
  const auth = useAuth();
  const nav = useNavigate();

  const logout = async () => {
    await auth.logout();
    nav({ to: "/login" });
  };

  return (
    <div className="flex flex-row items-center justify-between gap-2 flex-wrap w-56 md:w-auto">
      <CountryToggle />
      <CurrencyToggle />
      <ModeToggle />
      <LangToggle />
      <Button onClick={logout} variant="ghost">
        {t("logout")}
      </Button>
    </div>
  );
}
